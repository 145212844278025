import { IconProps } from "./utils/utils";

const FastReactionEventIcon = (props: IconProps) => {
    const { height = 20, width = 20, className } = props;
    return (
        <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
            <path
                d="M15.6483 15.2969H15.2733V8.35946C15.2733 8.1653 15.1159 8.0079 14.9217 8.0079H13.5155C13.3213 8.0079 13.1639 8.1653 13.1639 8.35946V15.2969H12.4608V10.7032C12.4608 10.509 12.3034 10.3516 12.1093 10.3516H10.703C10.5089 10.3516 10.3515 10.509 10.3515 10.7032V15.2969H9.64836V11.9922C9.64836 11.7981 9.49096 11.6407 9.2968 11.6407H7.89057C7.69642 11.6407 7.53901 11.7981 7.53901 11.9922V15.2969H6.8359V12.836C6.8359 12.6418 6.67849 12.4844 6.48434 12.4844H5.07811C4.88396 12.4844 4.72655 12.6418 4.72655 12.836V15.2969H4.35156C4.1574 15.2969 4 15.4543 4 15.6484C4 15.8426 4.1574 16 4.35156 16H15.6483C15.8424 16 15.9998 15.8426 15.9998 15.6484C15.9998 15.4543 15.8424 15.2969 15.6483 15.2969Z"
                fill="white"
            />
            <path
                d="M4.35156 11.5541C6.5457 11.5541 8.92509 10.7661 11.0514 9.33521C12.7168 8.21457 14.105 6.78295 14.9673 5.31703L15.3087 6.59124C15.3508 6.74831 15.554 6.88401 15.7393 6.83981C15.9281 6.79476 16.0381 6.59679 15.9879 6.40925L15.4122 4.26076C15.3619 4.07324 15.1692 3.96191 14.9816 4.01219L12.8331 4.58787C12.6456 4.63812 12.5343 4.83089 12.5845 5.01844C12.6348 5.20598 12.8275 5.31731 13.0151 5.26701L14.3992 4.89614C13.5947 6.29408 12.2652 7.67096 10.6589 8.75186C8.64733 10.1055 6.40735 10.851 4.35156 10.851C4.1574 10.851 4 11.0084 4 11.2025C4 11.3967 4.1574 11.5541 4.35156 11.5541Z"
                fill="white"
            />
        </svg>
    );
};


export  default FastReactionEventIcon;