import { useEffect, useState } from "react";
import { LayoutEntry } from "../../components/GridLayout/utils";
import { Graph } from "./Graphs/utils";
import { CustomDashboard, Dashboard } from "./utils";

export const useGetDefaultDashBoardLayout = ({ dashboardId }: { dashboardId: string }) => {
  const dashboards: Dashboard[] = [
    {
      id: CustomDashboard.example1,
      layout: [
        {
          i: Graph.OOMLimitGraph,
          x: 0,
          y: 0,
        },
        {
          i: Graph.LivenessProbeFailuresGraph,
          x: 1,
          y: 0,
        },
        {
          i: Graph.CPUThrottlingEventsGraph,

          x: 0,
          y: 1,
        },
        {
          i: Graph.CPUUnderProvisionedOnStressedNodesGraph,
          x: 1,
          y: 1,
        },
      ],
    },
    {
      id: CustomDashboard.example2,
      layout: [
        {
          i: Graph.AutomatedDowntimeGraph,
          x: 0,
          y: 0,
        },
        {
          i: Graph.OOMNodeGraph,
          x: 1,
          y: 0,
        },
        {
          i: Graph.LivenessProbeFailuresGraph,
          x: 0,
          y: 1,
        },
        {
          i: Graph.CPUThrottlingEventsGraph,
          x: 1,
          y: 1,
        },
      ],
    },
  ];

  const [defaultDashBoardLayout, setDefaultDashBoardLayout] = useState<LayoutEntry[]>(dashboards[0].layout);

  useEffect(() => {
    setDefaultDashBoardLayout(dashboards.find((dashboard) => dashboard.id === dashboardId)?.layout || []);
  }, [dashboardId]);

  return defaultDashBoardLayout;
};

export default useGetDefaultDashBoardLayout;
