import { GetTopKQueryKey } from "../../../api/fetcher";
import { getDisplayWorkloadKey } from "../../../utils/namesUtils";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps } from "./utils";

const WastefulGraph = ({ isMulticluster, tooltipTrigger, disabledZoom, updateActiveTooltips }: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab, enableHpaOptimization } =
    useGetGraphState();

  return (
    <TopKMultiLineChart
      title={
        <>
          Wasteful {aggregationView.toLowerCase()} <span className="text-[12px] text-strongBorder"> (monthly)</span>
        </>
      }
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey:
          aggregationView === AggregateBy.Workloads
            ? enableHpaOptimization
              ? GetTopKQueryKey.WastefulWorkloadsWithReplicas
              : GetTopKQueryKey.WastefulWorkloads
            : enableHpaOptimization
            ? GetTopKQueryKey.WastefulNamespacesWithReplicas
            : GetTopKQueryKey.WastefulNamespaces,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      YAxisFormatter={(value) => `$${value}`}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
      keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
    />
  );
};

export default WastefulGraph;
