import { GetTopKQueryKey } from "../../../api/fetcher";
import { getDisplayWorkloadKey } from "../../../utils/namesUtils";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps } from "./utils";

const ClusterDisruptionsGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title={
        aggregationView.toLowerCase() === "workloads" ? "Cluster workload disruptions" : "Cluster namespace disruptions"
      }
      infoTooltip={
        <>This graph displays all cluster disruptions such as rollouts, node downs, preemptions, and API evictions.</>
      }
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey:
          aggregationView === AggregateBy.Workloads
            ? GetTopKQueryKey.ClusterEvictionsWorkloads
            : GetTopKQueryKey.ClusterEvictionsNamespaces,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      dotted={true}
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
      keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
      hasStackedOffset
    />
  );
};

export default ClusterDisruptionsGraph;
