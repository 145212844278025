import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSortDirection } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetCostReportWorkloads, GetCostReportWorkloadsResponse } from "../../../../api/fetcher";
import { components } from "../../../../api/schema";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { ASC, DESC } from "../../../../components/WorkloadStatusByNamespace/utils";
import { getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../../utils/useStateWithLocalStorage";
import { PolicyTuningTabs } from "../../../Overview/PolicyTuning/utils";
import { Field, getSharedColumns, getSortedColumn } from "../utils";
import ExportWorkloadsCSV from "./ExportWorkloadsCSV";
import useWorkloadsFilters from "./hooks/useWorkloadsFilters";
import { WORKLOAD_ROW } from "./utils";
import ExploreEntityTooltip from "../../../../components/WorkloadStatusByNamespace/ExploreEntityTooltip";
import { useNavigate } from "react-router-dom";
import { POLICY_TUNING_SELECTED_TAB_QUERY_KEY } from "../../../Overview/PolicyTuning/PolicyTuning";
import {
  SCALEOPS_CRON_JOB_COG_NAME,
  SCALEOPS_KEDA_SCALED_JOB_COG_NAME,
  getWorkloadType,
} from "../../../../utils/namesUtils";
import { getWorkloadType as getWorkloadTypeForView, WorkloadType } from "../../../../utils/typesUtils";

const COST_PAGE_SIZE_LOCAL_STORAGE_KEY = "costPageSize";

const { queryKey, queryFn } = GetCostReportWorkloads();

const INITIAL_SORT_MODEL = [
  {
    field: String(Field.totalCost),
    sort: DESC as GridSortDirection,
  },
];

const getColumns = (selectedColumns: (string | undefined)[]): GridColDef[] => [
  {
    field: Field.workloadName,
    headerName: "Workload",
    hide: !selectedColumns.includes(Field.workloadName),
    flex: 3,
    minWidth: 150,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => {
      const workloadTypeForView = getWorkloadTypeForView(params.row.workloadName, params.row.workloadType);
      return (
        <ExploreEntityTooltip
          entityName={`${params.row.namespace}/${params.row.workloadName}`}
          entityType={workloadTypeForView}
          namespace={params.row.namespace}
          workloadName={params.row.workloadName}
        />
      );
    },
  },
  ...getSharedColumns(selectedColumns),
];

interface Props {
  selectedColumns: (string | undefined)[];
  isGpu: boolean;
}

const WorkloadsTable = ({ selectedColumns, isGpu }: Props) => {
  const filters = useWorkloadsFilters();

  const [rows, setRows] = useState<WORKLOAD_ROW[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortField, setSortField] = useQueryParam("sortField", withDefault(StringParam, String(Field.totalCost)));
  const [sortDirection, setSortDirection] = useQueryParam("sortDirection", withDefault(StringParam, DESC));
  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: COST_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });
  const enableHpaOptimization = useHpaOptimizationEnabled();

  const [searchTerm] = useQueryParam("searchTerms", StringParam);

  const { data, isLoading, error, isError } = useQuery<GetCostReportWorkloadsResponse, Error>({
    queryKey: [queryKey, filters, isGpu],
    queryFn: () =>
      queryFn({
        ...filters,
        gpuWorkloadsOnly: isGpu,
        multiCluster: true,
      }),
  });

  useEffect(() => {
    let sortedData = data?.aggregatedWorkloads
      ? data?.aggregatedWorkloads.map((wl) => {
          return {
            ...wl,
            displayWorkloadName: `${wl.namespace}/${wl.workloadName}`,
            savingsAvailable: enableHpaOptimization ? wl.savingsAvailableWithReplicas : wl.savingsAvailable,
            activeSavings: enableHpaOptimization ? wl.activeSavingsWithReplicas : wl.activeSavings,
          };
        })
      : [];

    // filter by searchTerm
    if (searchTerm) {
      sortedData = sortedData?.filter((wl) => {
        return wl.displayWorkloadName.includes(searchTerm);
      });
    }

    // sort by displayWorkloadName if sortField is workloadName
    if (sortField && sortDirection) {
      if (sortField === Field.workloadName) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.displayWorkloadName.localeCompare(b.displayWorkloadName);
          } else {
            return b.displayWorkloadName.localeCompare(a.displayWorkloadName);
          }
        });
      }
    }

    sortedData = getSortedColumn({ sortField, sortDirection, sortedData });

    sortedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];

    setRows(sortedData);
    setRowCount(data?.aggregatedWorkloads.length || 0);
  }, [data, page, pageSize, sortField, sortDirection, searchTerm, isGpu]);

  if (isError) {
    console.log("Error fetching data: ", error);
  }

  return (
    <div className="flex flex-col gap-3 relative">
      <DataGrid
        pagination={true}
        headerHeight={HEADER_HEIGHT}
        autoHeight
        rowHeight={ROW_HEIGHT}
        sx={{ ...getDataGridSx(false, false) }}
        columns={getColumns(selectedColumns)}
        rows={rows}
        initialState={{
          pagination: {
            pageSize: pageSize,
          },
        }}
        pageSize={pageSize}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={(x) => {
          if (isInitialSortModel) setIsInitialSortModel(false);
          setSortField(x[0]?.field);
          setSortDirection(x[0]?.sort);
        }}
        sortModel={
          isInitialSortModel && !sortField && !sortDirection
            ? INITIAL_SORT_MODEL
            : [
                {
                  field: String(sortField),
                  sort: String(sortDirection) as GridSortDirection,
                },
              ]
        }
        rowCount={rowCount}
        disableSelectionOnClick
        getCellClassName={() => "cursor-pointer"}
        onRowClick={(params: GridRowParams<components["schemas"]["UtilsWorkloadCostReport"]>) => {
          let workloadTypeForLink = getWorkloadType(params.row.workloadType);
          switch (true) {
            case workloadTypeForLink?.toLowerCase() === WorkloadType.KedaScaledJob.toLowerCase():
              workloadTypeForLink = SCALEOPS_KEDA_SCALED_JOB_COG_NAME;
              break;
            case workloadTypeForLink?.toLowerCase() === WorkloadType.CronJob.toLowerCase():
              workloadTypeForLink = SCALEOPS_CRON_JOB_COG_NAME;
              break;
            default:
              break;
          }
          workloadTypeForLink = workloadTypeForLink?.toLowerCase() ?? "";
          const targetTab = isGpu ? PolicyTuningTabs.Gpu : PolicyTuningTabs.PodRightsizing;
          const link = `/?currentClusterURLParam=${params.row.clusterName}&selectedWorkloadOverviewId=${params.row.clusterName}%2F${params.row.namespace}%2F${workloadTypeForLink}%2F${params.row.workloadName}&policyTuningViewPeriod=168&${POLICY_TUNING_SELECTED_TAB_QUERY_KEY}=${targetTab}`;
          navigate(link);
        }}
      />
      <ExportWorkloadsCSV isGpu={isGpu} />
    </div>
  );
};

export default WorkloadsTable;
