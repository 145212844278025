import { MenuItem } from "@mui/material";
import SingleSelect, { SingleSelectSize } from "../../components/SingleSelect";
import useGetGraphState from "./useGetGraphsState";
import { CustomDashboard } from "./utils";

const OPTIONS = Object.entries(CustomDashboard).map((option) => {
  return [option[0], option[1]];
});

const SelectDashboard = () => {
  const { selectedDashboard, setSelectedDashboard } = useGetGraphState();

  return (
    <SingleSelect<string>
      size={SingleSelectSize.Regular}
      className="w-[200px]"
      selected={selectedDashboard}
      setSelected={(option) => {
        setSelectedDashboard(option);
      }}
      renderOptionsFunc={() =>
        OPTIONS.map((option) => {
          return (
            <MenuItem value={option[1]} key={option[1]}>
              <p className="text-[14px]">{option[0]}</p>
            </MenuItem>
          );
        })
      }
    />
  );
};

export default SelectDashboard;
