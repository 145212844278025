import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import GridLayout from "../../components/GridLayout/GridLayout";
import { ChildImperativeHandle } from "../../components/GridLayout/utils";
import useFreezeTooltip from "../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { getGraphElements } from "./Graphs/utils";
import TroubleshootTopSection from "./TroubleshootTopSection";
import useGetDefaultDashBoardLayout from "./useGetDefaultDashBoardLayout";
import useGetGraphState from "./useGetGraphsState";

interface Props {
  isMultiCluster?: boolean;
}

const TroubleshootContainer = ({ isMultiCluster }: Props) => {
  const [selectedElements, setSelectedElements] = useState<(string | undefined)[]>([]);
  const { selectedDashboard } = useGetGraphState();
  const { tooltipTrigger, disabledZoom, updateActiveTooltips } = useFreezeTooltip({});

  const defaultDashBoardLayout = useGetDefaultDashBoardLayout({
    dashboardId: selectedDashboard,
  });

  const ref = useRef<ChildImperativeHandle>(null);
  const [saveIsActive, setSaveIsActive] = useState<boolean>(false);

  const handleSaveDashboard = () => {
    ref.current?.triggerClick();
  };

  useEffect(() => {
    setSelectedElements(defaultDashBoardLayout.map((element) => element.i));
  }, [defaultDashBoardLayout]);

  return (
    <div className="flex flex-col gap-6 min-w-[1280px]">
      <TroubleshootTopSection
        isMultiCluster={isMultiCluster}
        selectedElements={selectedElements}
        setSelectedElements={setSelectedElements}
      />
      <div>
        <div>
          <Button onClick={handleSaveDashboard} disabled={!saveIsActive} label="Save Dashboard" />
        </div>
        <GridLayout
          ref={ref}
          layoutId={selectedDashboard}
          elements={getGraphElements({
            isMulticluster: isMultiCluster,
            tooltipTrigger,
            updateActiveTooltips,
            disabledZoom,
          })}
          setSaveIsActive={setSaveIsActive}
          numberOfColumns={2}
          defaultElementColspan={1}
          defaultLayout={defaultDashBoardLayout}
          selectedElements={selectedElements}
          setSelectedElements={setSelectedElements}
        />
      </div>
    </div>
  );
};

export default TroubleshootContainer;
