import clsx from "clsx";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { toast } from "react-toastify";
import DragIcon from "../../Icons/DragIcon";
import { TOAST_SETTINGS } from "../../pages/Roles/mutations/utils";
import useStateWithLocalStorage from "../../utils/useStateWithLocalStorage";
import { ChildImperativeHandle, Cols, Elements, GirdLayout, LayoutEntry } from "./utils";

const ROW_HEIGHT = 266;
const NUMBER_OF_COLUMNS = 2;
const DEFAULT_ELEMENT_COLSPAN = 1;
const DEFAULT_ELEMENT_ROWSPAN = 1;

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface GridLayoutProps {
  layoutId: string;
  defaultLayout: LayoutEntry[];
  elements: Elements;
  saveLayout?: (layout: GirdLayout[]) => void;
  rowHeight?: number;
  cols?: Cols;
  numberOfColumns?: number;
  defaultElementColspan?: number;
  defaultElementRowspan?: number;
  setSaveIsActive?: (saveToActive: boolean) => void;
  selectedElements?: (string | undefined)[];
  setSelectedElements?: (selectedElements: (string | undefined)[]) => void;
}

const GridLayout = forwardRef<ChildImperativeHandle, GridLayoutProps>(
  (
    {
      layoutId,
      defaultLayout,
      elements,
      rowHeight = ROW_HEIGHT,
      cols,
      numberOfColumns = NUMBER_OF_COLUMNS,
      defaultElementColspan = DEFAULT_ELEMENT_COLSPAN,
      defaultElementRowspan = DEFAULT_ELEMENT_ROWSPAN,
      setSaveIsActive,
      selectedElements,
      setSelectedElements,
    },
    ref
  ) => {
    const [savedLayout, setSavedLayout] = useStateWithLocalStorage<string>({
      localStorageKey: `grid-layout-${layoutId}`,
      defaultValue: undefined,
    });
    const [changedLayout, setChangedLayout] = useState<GirdLayout[] | undefined>(undefined);
    const [layout, setLayout] = useState<GirdLayout[]>([]);
    const [isDraggable, setIsDraggable] = useState<boolean>(false);

    const handleSave = () => {
      toast.success("Dashboard was saved successfully", TOAST_SETTINGS);
      if (changedLayout) {
        setSavedLayout(JSON.stringify(changedLayout));
        setSaveIsActive && setSaveIsActive(false);
      }
    };

    useEffect(() => {
      if (selectedElements && setSelectedElements) {
        setSelectedElements(layout.map((entry) => entry.i));
      }
    }, [defaultLayout]);

    useEffect(() => {
      if (!selectedElements || !setSelectedElements) return;

      const newLayout: GirdLayout[] = [];

      selectedElements.forEach((elementId) => {
        if (!elementId) return;
        const element = elements[elementId];
        if (!element) return;

        const existingLayout = layout.find((entry) => entry.i === elementId);
        if (existingLayout) {
          newLayout.push(existingLayout);
          return;
        }

        newLayout.push({
          i: elementId,
          x: 0,
          y: 0,
          w: defaultElementColspan,
          h: defaultElementRowspan,
        });
      });

      setChangedLayout(newLayout);
      setLayout(newLayout);
    }, [selectedElements]);

    useEffect(() => {
      if (savedLayout && savedLayout.length > 0) {
        setLayout(JSON.parse(savedLayout) as GirdLayout[]);
        return;
      }

      setLayout(
        defaultLayout.map((entry) => ({
          ...entry,
          i: entry.i,
          w: Number(entry.width ?? defaultElementColspan),
          h: Number(entry.height ?? defaultElementRowspan),
        }))
      );
    }, [defaultLayout, savedLayout]);

    useImperativeHandle(ref, () => ({
      triggerClick: handleSave,
    }));

    return (
      <div className="border border-border rounded-lg p-3">
        <ResponsiveReactGridLayout
          isResizable={false}
          isBounded={true}
          isDraggable={isDraggable}
          containerPadding={[0, 0]}
          rowHeight={rowHeight}
          cols={
            cols ?? {
              lg: numberOfColumns,
              md: numberOfColumns,
              sm: numberOfColumns,
              xs: numberOfColumns,
              xxs: numberOfColumns,
            }
          }
          compactType="vertical"
          onLayoutChange={(layout) => {
            setChangedLayout(layout);
          }}
          onDrag={() => {
            setSaveIsActive && setSaveIsActive(true);
          }}
          layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
        >
          {layout.map((item) => {
            const element = elements[item.i];
            if (!element) return null;

            return (
              <div
                key={item.i}
                data-grid={item}
                className={clsx("block relative", {
                  "hover:shadow-lg": isDraggable,
                })}
              >
                <div
                  className="position absolute left-0 top-0 cursor-move h-[55px] text-black w-full transparent flex justify-end group"
                  onMouseEnter={() => setIsDraggable(true)}
                  onMouseLeave={() => setIsDraggable(false)}
                >
                  <DragIcon className="m-2 text-background-chip group-hover:text-black" width={16} height={15} />
                </div>
                {element}
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
);

export default GridLayout;
