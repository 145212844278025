import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
  GridSortItem,
} from "@mui/x-data-grid";
import { GridAlignment } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { getClusters, GetClustersResponse, GetDashboard, GetDashboardResponse } from "../api/fetcher";
import { components } from "../api/schema";
import { SCALEOPS_COLORS } from "../colors";
import { useMainContext } from "../MainContext";
import TopOverviewSection from "../pages/Overview/TopOverviewSection/TopOverviewSection";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import * as formatterUtils from "../utils/formatterUtils";
import { getDataGridSx, HEADER_HEIGHT } from "../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../utils/useGetTimeoutSeconds";
import * as componentUtils from "./componentUtils/overview";
import ProgressBar from "./ProgressBar";
import Tooltip from "./Tooltip";
import EditToolbarMultiClusterProps from "./WorkloadStatusByNamespace/BulkMultiClusterEditorToolbar";
import ExploreEntityTooltip from "./WorkloadStatusByNamespace/ExploreEntityTooltip";
import { CURRENT_CLUSTER_URL_QUERY_PARAM } from "./WorkloadStatusByNamespace/overviewUtils";
import { CustomTooltipPayload } from "./WorkloadStatusByNamespace/ResourceOverTime/CustomTooltip";
import ResourceOverTimeContainer from "./WorkloadStatusByNamespace/ResourceOverTime/ResourceOverTimeContainer";
import { ChartComponents } from "./WorkloadStatusByNamespace/ResourceOverTime/utils";
import useFilterQueryParams, { FilterParamObject } from "./WorkloadStatusByNamespace/useFilterQueryParams";
import { ExploreEntityMessage } from "./autoscalers/NodeOverview/ExploreWorkloadsMessage";

type Row = components["schemas"]["DashClusterRow"] & { tags?: string[] | undefined };
type Rows = Row[];
const dashboard = GetDashboard();
const clusterFetcher = getClusters();

export default function MultiClusters() {
  const { setCurrentCluster } = useMainContext();

  const navigate = useNavigate();

  const dataGridHeaderAlign: GridAlignment = "center";
  const currencyFormatter = formatterUtils.CurrencyFormatter();

  const [showAllocatable] = useState<boolean>(!FeatureEnabled("DemoVersion"));
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel | undefined>([1]);
  const [clusterTags, setClusterTags] = useState<Record<string, string[]>>({});
  const [uniqueClusterTags, setUniqueClusterTags] = useState<string[]>([]);
  const [rows, setRows] = useState<Rows>([]);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "totalNodesCost",
      sort: "desc",
    },
  ]);

  const [filteredTags] = useQueryParam("tags", ArrayParam);
  const [filteredClusters] = useQueryParam("clusters", ArrayParam);
  const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);
  const [searchTerm] = useQueryParam("searchTerm", StringParam);

  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const filterQueryParams: FilterParamObject = useFilterQueryParams();

  const updateSelection = (newSelectionModel: GridSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const {
    data: clustersData,
    isLoading: clustersIsLoading,
    error: clustersError,
    isError: clustersIsError,
  } = useQuery<GetClustersResponse, Error>({
    queryKey: [clusterFetcher.queryKey, "multicluster"],
    queryFn: () => clusterFetcher.queryFn(),
  });

  if (clustersIsError) {
    console.log("error loading clusters:", clustersError);
  }

  const {
    data: multiClusterOverview,
    isLoading,
    error,
    isError,
  } = useQuery<GetDashboardResponse, Error>({
    queryKey: [dashboard.queryKey, "multicluster", filterQueryParams],
    queryFn: () =>
      dashboard.queryFn({
        multiCluster: true,
        timeoutSeconds: timeoutSeconds,
        ...filterQueryParams,
      }),
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data: multiClusterOverview, isError: isError });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  if (isError) {
    console.log("error loading multicluster dashboard:", error);
  }

  useEffect(() => {
    if (clustersData?.clusters && clustersData.clusters.length > 0) {
      const tagsObjToSet = clustersData.clusters?.reduce((acc, cluster) => {
        const clusterName = cluster.name;
        if (clusterName && cluster.tags && cluster.tags.length > 0) {
          acc[clusterName] = cluster.tags;
          return acc;
        }
        return acc;
      }, {} as Record<string, string[]>);

      setClusterTags(tagsObjToSet);
      setUniqueClusterTags([...new Set(Object.values(tagsObjToSet)?.flat())].sort());
    }
  }, [clustersData]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Cluster name",
      flex: 1,
      type: "string",
      headerAlign: dataGridHeaderAlign,
      disableColumnMenu: true,
      sortable: true,
      minWidth: 180,
      renderCell: (
        params: GridRenderCellParams<
          string,
          components["schemas"]["UtilsTotalNamespaceSummary"] & { id: string; name: string },
          string
        >
      ) => {
        return (
          <ExploreEntityTooltip
            entityName={params.row.name}
            exploreEntityMessage={ExploreEntityMessage.ExploreClusterWorkloads}
          />
        );
      },
    },
    {
      field: "totalNodesCost",
      headerName: "Cost",
      flex: 0.7,
      type: "number",
      align: "center",
      headerAlign: dataGridHeaderAlign,
      disableColumnMenu: true,
      sortable: true,
      valueFormatter: ({ value }) => currencyFormatter.format(value as number),
      renderHeader: () => (
        <Tooltip title="Monthly Cost">
          <Typography variant="body2" fontWeight={700}>
            Monthly cost
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "monthlySavingsAvailable",
      headerName: "Available Savings",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: dataGridHeaderAlign,
      disableColumnMenu: true,
      sortable: true,
      cellClassName: (params: GridCellParams): string => {
        if (params.value > 0) {
          return "scaleops-success";
        }
        return "";
      },
      valueFormatter: ({ value }) => currencyFormatter.format(value as number),
      renderHeader: (params) => (
        <Tooltip title="Monthly Savings">
          <Typography variant="body2" fontWeight={700}>
            {params.colDef.headerName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "activeMonthlySavingsAvailable",
      headerName: "Active Savings",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: dataGridHeaderAlign,
      disableColumnMenu: true,
      sortable: true,
      valueFormatter: ({ value }) => currencyFormatter.format(value as number),
      renderHeader: (params) => (
        <Tooltip title="Active Savings">
          <Typography variant="body2" fontWeight={700}>
            {params.colDef.headerName}
          </Typography>
        </Tooltip>
      ),
      renderCell: (
        params: GridRenderCellParams<
          string,
          components["schemas"]["UtilsTotalNamespaceSummary"] & { id: string; name: string },
          string
        >
      ) => {
        const value = Number(params.row.activeMonthlySavingsAvailable);
        return (
          <div
            className={clsx("flex items-center justify-center", {
              "text-guideline-darkGreen": value > 0,
            })}
          >
            <Typography variant="body2">{currencyFormatter.format(value)}</Typography>
          </div>
        );
      },
    },

    {
      field: "underProvisionedWorkloads",
      headerName: "Under-Provisioned",
      type: "number",
      flex: 0.8,
      align: "center",
      headerAlign: dataGridHeaderAlign,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: (params: GridCellParams): string => {
        if (params.value > 0) {
          return "scaleops-error";
        }
        return "";
      },
    },
    {
      field: "automatedWorkloads",
      headerName: "Automated %",
      type: "number",
      flex: 0.8,
      align: "center",
      headerAlign: dataGridHeaderAlign,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["UtilsTotalNamespaceSummary"], string>
      ) => {
        const automatedWorkloads = params.row.automatedWorkloads;
        const unAutomatedWorkloads = params.row.unautomatedWorkloads;
        const totalWorkloads = automatedWorkloads + unAutomatedWorkloads;

        let percentageOfAutomatedWorkloads = Math.round((automatedWorkloads / totalWorkloads) * 100);

        switch (true) {
          case percentageOfAutomatedWorkloads > 100:
            percentageOfAutomatedWorkloads = 100;
            break;
          case percentageOfAutomatedWorkloads < 0:
            percentageOfAutomatedWorkloads = 0;
            break;
          case isNaN(percentageOfAutomatedWorkloads):
            percentageOfAutomatedWorkloads = 0;
            break;
        }

        return (
          <ProgressBar
            percentage={percentageOfAutomatedWorkloads}
            mainFill={SCALEOPS_COLORS.main.green}
            hasTopDisplayNumber
          />
        );
      },
    },
  ];

  const flattenClusterRows = (
    clusterRows: components["schemas"]["DashClusterRow"][] | undefined
  ): (components["schemas"]["UtilsTotalNamespaceSummary"] & { id: string; name: string })[] => {
    if (clusterRows !== undefined) {
      return clusterRows
        .filter(
          (clusterRow: components["schemas"]["DashClusterRow"] | undefined) =>
            clusterRow !== undefined && clusterRow.name !== undefined
        )
        .map((clusterRow: components["schemas"]["DashClusterRow"]) => {
          return {
            id: clusterRow.name || "",
            name: clusterRow.name || "",
            ...clusterRow.totalSummary,
          } as components["schemas"]["UtilsTotalNamespaceSummary"] & { id: string; name: string };
        });
    }
    return [];
  };

  useEffect(() => {
    if (multiClusterOverview?.clusterRows) {
      let rowsToSet = flattenClusterRows(multiClusterOverview?.clusterRows);
      rowsToSet = rowsToSet.map((row) => {
        return {
          ...row,
          tags: clusterTags[row.id],
        };
      });

      let filteredRow: Rows = rowsToSet;

      /**
       * Filter by tag
       */
      if (filteredTags && filteredTags.length > 0) {
        filteredRow = filteredRow.filter((row) => {
          if (isTagsExclude) {
            if (row.tags === undefined || row.tags.length === 0) return true;
            return filteredTags.every((tag) => tag && !row.tags?.includes(tag));
          }
          return filteredTags.some((tag) => tag && row.tags?.includes(tag));
        });
      }

      /**
       * Filter by cluster
       */
      if (filteredClusters && filteredClusters.length > 0) {
        filteredRow = filteredRow.filter((row) => {
          return filteredClusters.some((cluster) => cluster === row.name);
        });
      }

      /**
       * Filter by search term
       */
      if (searchTerm) {
        filteredRow = filteredRow.filter((row) => {
          return row.name?.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }

      setRows(filteredRow);
    }
  }, [multiClusterOverview, filteredTags, isTagsExclude, filteredClusters, searchTerm]);

  return componentUtils.windowBlock(
    "",
    <Box key="cluster">
      <div className="min-w-[1300px]">
        <TopOverviewSection
          monthlyForecast={multiClusterOverview?.totalSummary?.totalNodesCost}
          monthlySavingsAvailable={multiClusterOverview?.totalSummary?.monthlySavingsAvailable}
          activeMonthlySavingsAvailable={multiClusterOverview?.totalSummary?.activeMonthlySavingsAvailable}
          underProvisionedWorkloads={multiClusterOverview?.totalSummary?.underProvisionedWorkloads}
          overProvisionedWorkloads={multiClusterOverview?.totalSummary?.overProvisionedWorkloads}
          totalAutomatedWorkloads={multiClusterOverview?.totalSummary?.automatedWorkloads}
          totalUnAutomatedWorkloads={multiClusterOverview?.totalSummary?.unautomatedWorkloads}
          isMulticluster
          isLoading={isLoading}
        />
        <div className="mt-[64px]">
          <ResourceOverTimeContainer
            showRecommended
            showAllocatable={showAllocatable}
            getWasteValue={(payload: CustomTooltipPayload) => payload.request - payload.recommended}
            includedChartComponents={[
              ChartComponents.Usage,
              ChartComponents.Request,
              ChartComponents.WasteFromRequestToRecommended,
              ChartComponents.Waste,
              ChartComponents.Recommended,
              ChartComponents.Allocatable,
            ]}
            defaultSelectedChartComponents={[
              ChartComponents.Usage,
              ChartComponents.Request,
              ChartComponents.WasteFromRequestToRecommended,
              ChartComponents.Waste,
              ChartComponents.Recommended,
            ]}
            isMultiCluster
          />
        </div>
        <div>
          <div className="bg-white p-[20px] mt-[30px]">
            <DataGrid
              headerHeight={HEADER_HEIGHT}
              rowHeight={90}
              sx={{
                ...getDataGridSx(),
                border: "none",
                "& .MuiDataGrid-cell:first-child": {
                  borderLeft: "1px solid #c4c4c4",
                },
                "& .MuiDataGrid-cell:last-child": {
                  borderRight: "1px solid #c4c4c4",
                },
                "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
                  borderBottom: "1px solid #c4c4c4",
                },
              }}
              loading={isLoading || clustersIsLoading}
              columns={columns}
              rows={rows}
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight={true}
              disableSelectionOnClick
              hideFooter={true}
              style={{ borderRadius: "5px", cursor: "pointer" }}
              onSelectionModelChange={updateSelection}
              selectionModel={selectionModel}
              components={{
                Toolbar: EditToolbarMultiClusterProps,
              }}
              componentsProps={{
                toolbar: {
                  uniqueClusterTags,
                },
              }}
              onSortModelChange={(model) => setSortModel(model)}
              sortModel={sortModel}
              onRowClick={(params: GridRowParams<Row>) => {
                if (params.row.name) {
                  setCurrentCluster(params.row.name);
                  navigate(`/?${CURRENT_CLUSTER_URL_QUERY_PARAM}=${params.row.name}`);
                }
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
