import MultiSelect from "../../components/MultiSelect";
import RangePicker, { RangeOptions } from "../../components/RangePicker/RangePicker";
import VerticalSeparator from "../../components/VerticalSeparator";
import TopKFilters from "../Analytics/AnalyticsV2/Graphs/TopKFilters";
import NumberOfElementsFilter from "../Analytics/AnalyticsV2/NumberOfElementsFilter";
import SelectAggregationView from "../Analytics/AnalyticsV2/SelectAggregationView";
import { Graph, GraphDisplayName } from "./Graphs/utils";
import SelectDashboard from "./SelectDashboard";
import useGetGraphState from "./useGetGraphsState";

interface Props {
  isMultiCluster?: boolean;
  selectedElements: (string | undefined)[];
  setSelectedElements: (selectedElements: (string | undefined)[]) => void;
}

const TroubleshootTopSection = ({ isMultiCluster, selectedElements, setSelectedElements }: Props) => {
  const { date, setDate } = useGetGraphState();

  return (
    <div className="w-full rounded-lg flex bg-white relative p-8 gap-4">
      <div className="w-full flex gap-4 items-center">
        <SelectDashboard />
        <MultiSelect
          selected={selectedElements}
          setSelected={setSelectedElements}
          options={Object.keys(Graph)}
          modifyOptionText={(option) => GraphDisplayName[option as keyof typeof GraphDisplayName]}
          width={200}
        />
        <VerticalSeparator />
        <NumberOfElementsFilter />
        {!isMultiCluster && <SelectAggregationView />}
        <VerticalSeparator />
        <TopKFilters isMultiCluster={isMultiCluster} />
      </div>
      <div className="flex items-center gap-4">
        <VerticalSeparator />
        <div>
          <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.SHORT} minDateRangeInDays={14} />
        </div>
      </div>
    </div>
  );
};

export default TroubleshootTopSection;
