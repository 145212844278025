import { LayoutEntry } from "../../components/GridLayout/utils";

export const GENERAL_SYNC_ID = "general_sync_id";

export type Dashboard = {
  id: string;
  layout: LayoutEntry[];
};

export enum CustomDashboard {
  example1 = "example 1",
  example2 = "example 2",
}
