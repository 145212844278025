import { Checkbox, ListItemText, MenuItem, Chip as MUIChip, Typography } from "@mui/material";
import { GridFilterListIcon } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { GetNodeFilterValues, GetNodeFilterValuesResponse } from "../../api/fetcher";
import { components } from "../../api/schema";
import { MAIN_YELLOW } from "../../colors";
import InfoIcon from "../../Icons/InfoIcon";
import UnFilterIcon from "../../Icons/UnFilterIcon";
import WarningIcon from "../../Icons/WarningIcon";
import CustomSelectedFilterChip from "../CustomSelectedFilterChip";
import MultiSelect from "../MultiSelect";
import { BlockedNodeReasons } from "../unevictable/BlockedNodesTable/utils";
import useGetAnnotations from "../WorkloadStatusByNamespace/OverviewHooks/useGetAnnotations";
import useGetLabels from "../WorkloadStatusByNamespace/OverviewHooks/useGetLabels";
import useGetWorkloadNames from "../WorkloadStatusByNamespace/OverviewHooks/useGetWorkloadNames";
import useGetRebalanceQueryParams from "./Consolidate/useGetRebalanceQueryParams";
import SearchNodeFilter from "./SearchNodeFilter";

export const BLOCKED_NODES_CATEGORY_ORDER = [
  BlockedNodeReasons.OptimizeUnevictablePods,
  BlockedNodeReasons.ExploreNodeRestriction,
  BlockedNodeReasons.ChangeClusterAutoScalerConfiguration,
  BlockedNodeReasons.ExploreSchedulingReasons,
];

export const sortByBlockedNodeReasonsCategory = (
  a: string,
  b: string,
  _?: "asc" | "desc",
  availableScaleDownReasonsWarn?: components["schemas"]["NodeGroupsScaleDownReason"][]
) => {
  const aCategory = String(availableScaleDownReasonsWarn?.find((entity) => entity.reason == a)?.category ?? "");
  const bCategory = String(availableScaleDownReasonsWarn?.find((entity) => entity.reason == b)?.category ?? "");

  if (aCategory && bCategory) {
    return (
      BLOCKED_NODES_CATEGORY_ORDER.indexOf(aCategory as BlockedNodeReasons) -
      BLOCKED_NODES_CATEGORY_ORDER.indexOf(bCategory as BlockedNodeReasons)
    );
  }
  return 0;
};

export enum NodeFilters {
  Search = "search",
  ScaleDownBlockers = "scaleDownBlockers",
  NodeGroups = "nodeGroups",
  NodePools = "nodePools",
  Provisioners = "provisioners",
  AvailabilityZones = "availabilityZones",
  Labels = "labels",
  WorkloadLabels = "workloadLabels",
  WorkloadAnnotations = "workloadAnnotations",
  WorkloadNames = "workloadNames",
}

const convertReasonToTextToDisplay = (option: string) => {
  let displayTestArr = option.split(/([A-Z][a-z0-9]*)/g).filter((word) => word !== "");
  displayTestArr = displayTestArr.map((word, index) => (index === 0 ? word : word.toLowerCase()));
  return displayTestArr
    .join(" ")
    ?.replace(/([a-zA-Z])([0-9])/g, "$1 $2")
    ?.replace(/([0-9])([a-zA-Z])/g, "$1 $2");
};

const optionRenderFunction = (option: string, index: number, selected: (string | undefined)[], dataTestId?: string) => {
  const displayText = convertReasonToTextToDisplay(option);

  return (
    <MenuItem
      value={option}
      key={`${index}-multi-select-option`}
      data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
    >
      <Checkbox checked={selected.indexOf(option) > -1} />
      <ListItemText primary={displayText} />
    </MenuItem>
  );
};

const convertLabelsToSimpleStrings = (labels: components["schemas"]["NodeGroupsKeyValue"][]) => {
  return labels.map((label) => `${label?.key}=${label?.value}`);
};

export const ConvertArrayParamToStringArray = (param: (string | null)[] | null | undefined) => {
  if (param === null || param === undefined) {
    return [];
  }
  return param.filter((item) => item ?? "").map((item) => item as string);
};

interface Props {
  filters: NodeFilters[];
  queryParamsPrefix?: string;
}

export const NodeFilterBar = ({ filters, queryParamsPrefix = "" }: Props) => {
  const {
    searchTerm,
    setSearchTerm,
    selectedNodeGroups,
    setSelectedNodeGroups: setNodeGroups,
    selectedNodePools,
    setSelectedNodePools: setNodePools,
    selectedProvisioners,
    setSelectedProvisioners: setProvisioners,
    selectedAvailabilityZones,
    setSelectedAvailabilityZones: setAvailabilityZones,
    selectedScaleDownReasons,
    selectedLabels,
    setSelectedLabels: setLabels,
    selectedWorkloadLabels,
    setSelectedWorkloadLabels: setWorkloadLabels,
    selectedWorkloadAnnotations,
    setSelectedWorkloadAnnotations: setWorkloadAnnotations,
    selectedWorkloadNames,
    setSelectedWorkloadNames: setWorkloadNames,
    selectedScaleDownReasonsWarn,
    setSelectedScaleDownReasonsWarn: setScaleDownReasonsWarn,
    selectedScaleDownReasonsInfo,
    setSelectedScaleDownReasonsInfo: setScaleDownReasonsInfo,
  } = useGetRebalanceQueryParams(queryParamsPrefix);

  const nodeFiltersQuery = GetNodeFilterValues();
  const workloadLabels = useGetLabels();
  const workloadAnnotations = useGetAnnotations();
  const workloadNames = useGetWorkloadNames();

  const { data, isLoading } = useQuery<GetNodeFilterValuesResponse, Error>({
    queryKey: [nodeFiltersQuery.queryKey],
    queryFn: nodeFiltersQuery.queryFn,
    refetchInterval: 1000 * 60 * 5,
  });

  const availableNodeGroups = data?.nodeGroups || [];
  const availableAvailabilityZones = data?.availabilityZones || [];
  const availableNodePools = data?.nodePools || [];
  const availableLabels = data?.labels || [];
  const availableProvisioners = data?.provisioners || [];
  const availableScaleDownReasonsWarn = data?.scaleDownReasonsWarn || [];
  const availableScaleDownReasonsInfo = data?.scaleDownReasonsInfo || [];

  const displayAvailableLabels = convertLabelsToSimpleStrings(availableLabels);

  const isAnySelected = (param: (string | null)[] | null | undefined) => {
    return param?.length || 0 > 0;
  };

  if (isLoading) {
    return null;
  }

  const hasSearchFilter = filters.includes(NodeFilters.Search);
  const hasNodeGroupsFilter = filters.includes(NodeFilters.NodeGroups);
  const hasNodePoolsFilter = filters.includes(NodeFilters.NodePools);
  const hasProvisionersFilter = filters.includes(NodeFilters.Provisioners);
  const hasAvailabilityZonesFilter = filters.includes(NodeFilters.AvailabilityZones);
  const hasLabelsFilter = filters.includes(NodeFilters.Labels);
  const hasWorkloadLabelsFilter = filters.includes(NodeFilters.WorkloadLabels);
  const hasWorkloadAnnotationsFilter = filters.includes(NodeFilters.WorkloadAnnotations);
  const hasWorkloadNamesFilter = filters.includes(NodeFilters.WorkloadNames);

  return (
    <div className={"flex flex-col gap-1 mb-4"}>
      <div className="w-auto flex gap-2 item flex-wrap">
        {hasSearchFilter && <SearchNodeFilter queryParamsPrefix={queryParamsPrefix} />}
        <MultiSelect
          selected={ConvertArrayParamToStringArray(selectedScaleDownReasonsWarn)}
          setSelected={setScaleDownReasonsWarn as (props: (string | undefined)[]) => void}
          options={availableScaleDownReasonsWarn.map((entity) => entity.reason as string)}
          sortFnc={sortByBlockedNodeReasonsCategory}
          className="w-[180px]"
          sort="desc"
          customIcon={
            <MUIChip
              variant={"outlined"}
              sx={{
                background: isAnySelected(selectedScaleDownReasonsWarn) ? "#595959" : undefined,
                color: isAnySelected(selectedScaleDownReasonsWarn) ? "white" : "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
              }}
              label={
                <div className="flex items-center justify-center gap-1">
                  {/* <GridFilterListIcon fontSize={"small"} /> */}
                  <WarningIcon fill={MAIN_YELLOW} />
                  scale down blockers
                </div>
              }
            />
          }
          optionRenderFunction={(
            option: string,
            index: number,
            selected: (string | undefined)[],
            dataTestId?: string
          ) => {
            const displayText =
              option === "PodOwnerReachedToMinimumReplicas" ? "Pod has no owner" : convertReasonToTextToDisplay(option);

            return (
              <MenuItem
                value={option}
                key={`${index}-multi-select-option`}
                data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
              >
                <Checkbox checked={selected.indexOf(option) > -1} />
                <ListItemText primary={displayText} />
                <span className="px-4 bg-main-green font-bold text-white text-[10px] rounded-lg ml-2">
                  {availableScaleDownReasonsWarn.find((entity) => entity.reason == option)?.count}
                </span>
              </MenuItem>
            );
          }}
        />
        <MultiSelect
          selected={ConvertArrayParamToStringArray(selectedScaleDownReasonsInfo)}
          setSelected={setScaleDownReasonsInfo as (props: (string | undefined)[]) => void}
          options={availableScaleDownReasonsInfo.map((entity) => entity.reason as string)}
          className="w-[200px]"
          sort="asc"
          customIcon={
            <MUIChip
              variant={"outlined"}
              sx={{
                background: isAnySelected(selectedScaleDownReasonsInfo) ? "#595959" : undefined,
                color: isAnySelected(selectedScaleDownReasonsInfo) ? "white" : "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
              }}
              label={
                <div className="flex items-center justify-center gap-1">
                  {/* <GridFilterListIcon fontSize={"small"} /> */}
                  <InfoIcon />
                  scale down constraints
                </div>
              }
            />
          }
          optionRenderFunction={(
            option: string,
            index: number,
            selected: (string | undefined)[],
            dataTestId?: string
          ) => {
            const displayText =
              option === "PodOwnerReachedToMinimumReplicas" ? "Pod has no owner" : convertReasonToTextToDisplay(option);

            return (
              <MenuItem
                value={option}
                key={`${index}-multi-select-option`}
                data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
              >
                <Checkbox checked={selected.indexOf(option) > -1} />
                <ListItemText primary={displayText} />
                <span className="px-4 bg-guideline-darkPurple text-white text-[10px] rounded-lg ml-2">
                  {availableScaleDownReasonsInfo.find((entity) => entity.reason == option)?.count}
                </span>
              </MenuItem>
            );
          }}
        />
        {hasNodeGroupsFilter && availableNodeGroups && availableNodeGroups.length > 0 && (
          <MultiSelect
            isSearchable={true}
            selected={ConvertArrayParamToStringArray(selectedNodeGroups)}
            setSelected={setNodeGroups as (props: (string | undefined)[]) => void}
            options={availableNodeGroups}
            className="w-[128px]"
            sort="asc"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedNodeGroups) ? "#595959" : undefined,
                  color: isAnySelected(selectedNodeGroups) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    node groups
                  </div>
                }
              />
            }
          />
        )}
        {hasProvisionersFilter && availableProvisioners && availableProvisioners.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedProvisioners)}
            setSelected={setProvisioners as (props: (string | undefined)[]) => void}
            options={availableProvisioners}
            className="w-[123px]"
            sort="asc"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedProvisioners) ? "#595959" : undefined,
                  color: isAnySelected(selectedProvisioners) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    provisioners
                  </div>
                }
              />
            }
            optionRenderFunction={optionRenderFunction}
          />
        )}
        {hasNodePoolsFilter && availableNodePools && availableNodePools.length > 0 && (
          <MultiSelect
            isSearchable={true}
            selected={ConvertArrayParamToStringArray(selectedNodePools)}
            setSelected={setNodePools as (props: (string | undefined)[]) => void}
            options={availableNodePools}
            className="w-[105px]"
            sort="asc"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedNodePools) ? "#595959" : undefined,
                  color: isAnySelected(selectedNodePools) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    node pools
                  </div>
                }
              />
            }
            optionRenderFunction={optionRenderFunction}
          />
        )}
        {hasAvailabilityZonesFilter && availableAvailabilityZones && availableAvailabilityZones.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedAvailabilityZones)}
            setSelected={setAvailabilityZones as (props: (string | undefined)[]) => void}
            options={availableAvailabilityZones}
            className="w-[156px]"
            sort="asc"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedAvailabilityZones) ? "#595959" : undefined,
                  color: isAnySelected(selectedAvailabilityZones) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    availability zones
                  </div>
                }
              />
            }
          />
        )}
        {hasLabelsFilter && displayAvailableLabels && displayAvailableLabels.length > 0 && (
          <MultiSelect
            isSearchable={true}
            hasVirtualizedList={true}
            selected={ConvertArrayParamToStringArray(selectedLabels)}
            setSelected={setLabels as (props: (string | undefined)[]) => void}
            options={displayAvailableLabels}
            sort="asc"
            className="w-[87.1px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedLabels) ? "#595959" : undefined,
                  color: isAnySelected(selectedLabels) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    labels
                  </div>
                }
              />
            }
          />
        )}
        {hasWorkloadLabelsFilter && workloadLabels && workloadLabels.length > 0 && (
          <MultiSelect
            isSearchable={true}
            hasVirtualizedList={true}
            selected={ConvertArrayParamToStringArray(selectedWorkloadLabels)}
            setSelected={setWorkloadLabels as (props: (string | undefined)[]) => void}
            options={workloadLabels}
            sort="asc"
            className="w-[147.39px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedWorkloadLabels) ? "#595959" : undefined,
                  color: isAnySelected(selectedWorkloadLabels) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    workload labels
                  </div>
                }
              />
            }
          />
        )}
        {hasWorkloadAnnotationsFilter && availableLabels && availableLabels.length > 0 && (
          <MultiSelect
            isSearchable={true}
            hasVirtualizedList={true}
            selected={ConvertArrayParamToStringArray(selectedWorkloadAnnotations)}
            setSelected={setWorkloadAnnotations as (props: (string | undefined)[]) => void}
            options={workloadAnnotations}
            sort="asc"
            className="w-[187.69px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedWorkloadAnnotations) ? "#595959" : undefined,
                  color: isAnySelected(selectedWorkloadAnnotations) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    workload annotations
                  </div>
                }
              />
            }
          />
        )}
        {hasWorkloadNamesFilter && workloadNames && workloadNames.length > 0 && (
          <MultiSelect
            isSearchable={true}
            hasVirtualizedList={true}
            selected={ConvertArrayParamToStringArray(selectedWorkloadNames)}
            setSelected={setWorkloadNames as (props: (string | undefined)[]) => void}
            options={workloadNames}
            sort="asc"
            className="w-[152.px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedWorkloadNames) ? "#595959" : undefined,
                  color: isAnySelected(selectedWorkloadNames) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    workload names
                  </div>
                }
              />
            }
          />
        )}
      </div>
      <div className={"flex gap-2 flex-wrap mb-1.5 max-w-full"}>
        {searchTerm && searchTerm.length > 0 && (
          <CustomSelectedFilterChip
            label={searchTerm}
            onClick={() => {
              setSearchTerm("");
            }}
            hasTooltip
            tooltipContent={
              <>
                search: <br />
                {searchTerm}
              </>
            }
            className="max-w-[250px] truncate"
          />
        )}
        {selectedScaleDownReasonsWarn?.map((reason) => {
          const parsedReason = convertReasonToTextToDisplay(reason ?? "");
          return (
            <CustomSelectedFilterChip
              label={parsedReason}
              onClick={() => {
                setScaleDownReasonsWarn(selectedScaleDownReasonsWarn?.filter((item) => item !== reason));
              }}
              hasTooltip
              tooltipContent={
                <>
                  scale down blockers: <br />
                  {parsedReason}
                </>
              }
              className="max-w-[250px] truncate"
            />
          );
        })}
        {selectedScaleDownReasonsInfo?.map((reason) => {
          const parsedReason = convertReasonToTextToDisplay(reason ?? "");
          return (
            <CustomSelectedFilterChip
              label={parsedReason}
              onClick={() => {
                setScaleDownReasonsInfo(selectedScaleDownReasonsInfo?.filter((item) => item !== reason));
              }}
              hasTooltip
              tooltipContent={
                <>
                  scale down constraints: <br />
                  {parsedReason}
                </>
              }
              className="max-w-[250px] truncate"
            />
          );
        })}
        {selectedNodeGroups?.map((nodeGroup) => (
          <CustomSelectedFilterChip
            label={nodeGroup ?? ""}
            onClick={() => {
              setNodeGroups(selectedNodeGroups?.filter((item) => item !== nodeGroup));
            }}
            hasTooltip
            tooltipContent={
              <>
                node group: <br />
                {nodeGroup}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedProvisioners?.map((provisioner) => (
          <CustomSelectedFilterChip
            label={provisioner ?? ""}
            onClick={() => {
              setProvisioners(selectedProvisioners?.filter((item) => item !== provisioner));
            }}
            hasTooltip
            tooltipContent={
              <>
                provisioner: <br />
                {provisioner}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedNodePools?.map((nodePool) => (
          <CustomSelectedFilterChip
            label={nodePool ?? ""}
            onClick={() => {
              setNodePools(selectedNodePools?.filter((item) => item !== nodePool));
            }}
            hasTooltip
            tooltipContent={
              <>
                node pool: <br />
                {nodePool}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedAvailabilityZones?.map((availabilityZone) => (
          <CustomSelectedFilterChip
            label={availabilityZone ?? ""}
            onClick={() => {
              setAvailabilityZones(selectedAvailabilityZones?.filter((item) => item !== availabilityZone));
            }}
            hasTooltip
            tooltipContent={
              <>
                availability zone: <br />
                {availabilityZone}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedLabels?.map((label) => (
          <CustomSelectedFilterChip
            label={label ?? ""}
            onClick={() => {
              setLabels(selectedLabels?.filter((item) => item !== label));
            }}
            hasTooltip
            tooltipContent={
              <>
                label: <br />
                {label}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedWorkloadLabels?.map((label) => (
          <CustomSelectedFilterChip
            label={label ?? ""}
            onClick={() => {
              setWorkloadLabels(selectedWorkloadLabels?.filter((item) => item !== label));
            }}
            hasTooltip
            tooltipContent={
              <>
                workload label: <br />
                {label}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedWorkloadAnnotations?.map((annotation) => (
          <CustomSelectedFilterChip
            label={annotation ?? ""}
            onClick={() => {
              setWorkloadAnnotations(selectedWorkloadAnnotations?.filter((item) => item !== annotation));
            }}
            hasTooltip
            tooltipContent={
              <>
                workload annotation: <br />
                {annotation}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedWorkloadNames?.map((name) => (
          <CustomSelectedFilterChip
            label={name ?? ""}
            onClick={() => {
              setWorkloadNames(selectedWorkloadNames?.filter((item) => item !== name));
            }}
            hasTooltip
            tooltipContent={
              <>
                workload name: <br />
                {name}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
      </div>
      {((searchTerm && searchTerm.length > 0) ||
        selectedScaleDownReasons?.length ||
        selectedNodeGroups?.length ||
        selectedProvisioners?.length ||
        selectedWorkloadLabels?.length ||
        selectedNodePools?.length ||
        selectedAvailabilityZones?.length ||
        selectedLabels?.length ||
        selectedWorkloadAnnotations?.length ||
        selectedWorkloadNames?.length ||
        selectedScaleDownReasonsWarn?.length ||
        selectedScaleDownReasonsInfo?.length) && (
        <Typography
          variant="caption"
          onClick={() => {
            setSearchTerm(undefined);
            setNodeGroups([]);
            setNodePools([]);
            setProvisioners([]);
            setAvailabilityZones([]);
            setLabels([]);
            setWorkloadLabels([]);
            setWorkloadAnnotations([]);
            setWorkloadNames([]);
            setScaleDownReasonsWarn([]);
            setScaleDownReasonsInfo([]);
          }}
          className="w-fit hover:cursor-pointer hover:text-guideline-lessDarkBlue bg-white hover:bg-guideline-lightGray px-2 py-1 border-[0.5px] border-black hover:border-border rounded-lg flex items-center justify-center gap-1"
        >
          <UnFilterIcon width={15} height={15} />
          <p>Reset filters</p>
        </Typography>
      )}
    </div>
  );
};
