import { GetTopKQueryKey } from "../../../api/fetcher";
import { memoryDataParser } from "../../../utils/graphUtils";
import { getDisplayWorkloadKey } from "../../../utils/namesUtils";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps } from "./utils";

const MemoryUnderProvisionedOnStressedNodes = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title={"Memory under-provisioned " + aggregationView.toLowerCase() + " on stressed nodes"}
      infoTooltip={
        <>
          <b>Sum</b> of <b>usage above request</b> for workloads running on <b>Memory stressed</b> nodes.
          <br />
          These workloads can lead to stressed nodes and cause performance issues to their neighbors.
        </>
      }
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey:
          aggregationView === AggregateBy.Workloads
            ? GetTopKQueryKey.MemoryUnderProvisionedWorkloadsStressedNode
            : GetTopKQueryKey.MemoryUnderProvisionedNamespacesStressedNode,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      YAxisFormatter={(x: string) => memoryDataParser(Number(x))}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
      renderNameFunction={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
      keyParser={aggregationView === AggregateBy.Workloads ? getDisplayWorkloadKey : undefined}
    />
  );
};

export default MemoryUnderProvisionedOnStressedNodes;
