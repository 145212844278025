import { GetTopKQueryKey } from "../../../api/fetcher";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import useGetGraphState from "../useGetGraphsState";
import { CommonGraphProps, NODE_SYNC_ID } from "./utils";

const NodeLifeCycleGraph = ({
  isMulticluster,
  tooltipTrigger,
  disabledZoom,
  updateActiveTooltips,
}: CommonGraphProps) => {
  const { date, setDate, numberOfElements, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title="Nodes lifecycle"
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey: GetTopKQueryKey.NodeUsageType,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={NODE_SYNC_ID}
      disabledZoom={disabledZoom}
      tooltipTrigger={tooltipTrigger}
    />
  );
};

export default NodeLifeCycleGraph;
