import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GetVersion, GetVersionResponse } from "../../api/fetcher";
import { useMainContext } from "../../MainContext";
import { OverviewLinkType } from "../../utils/graphUtils";
import { getDisplayWorkloadName, workloadTypes } from "../../utils/namesUtils";
import { getWorkloadType } from "../../utils/typesUtils";
import ExploreEntityTooltip from "../../utils/exploreEntityTooltip";
import ChartTooltipElement from "../ChartTooltipElement";

const VERSION_THRESHOLD = "1.5.2";

interface WorkloadIdentifiers {
  cluster?: string;
  type: string;
  namespace: string;
  name: string;
}

interface Props {
  color: string;
  value: React.ReactNode;
  label: React.ReactNode;
  rawLabel: string;
  openInNewTab?: boolean;
  disableLink?: boolean;
  textToCopyOnClick?: string;
  source?: OverviewLinkType;
  targetResource?: OverviewLinkType;
  secondaryValue?: React.ReactNode;
  hasLimitedWidth?: boolean;
}

export const buildNodeOverviewLink = (nodeId: string, currentCluster?: string) => {
  const currentClusterURLParam = currentCluster ? `currentClusterURLParam=${currentCluster}` : "";
  const searchTermUrlParam = `setSearchTerm=${nodeId.toLocaleLowerCase()}`;
  return `/nodes?${currentClusterURLParam}&${searchTermUrlParam}&openFirstRowNodeOverview=1`;
};

const { queryKey, queryFn } = GetVersion();
const getIsVersionXGreaterThanVersionY = (versionX: string | undefined, versionY: string | undefined): boolean => {
  if (!versionX || !versionY) {
    return false;
  }
  const versionXArray = versionX.split(".");
  const versionYArray = versionY.split(".");
  for (let i = 0; i < versionXArray.length; i++) {
    if (parseInt(versionXArray[i]) >= parseInt(versionYArray[i])) {
      return true;
    }
  }
  return false;
};

const LinkableTooltipElement = ({
  color,
  value,
  label,
  rawLabel,
  openInNewTab = true,
  disableLink,
  textToCopyOnClick,
  source = OverviewLinkType.Workload,
  targetResource = OverviewLinkType.Workload,
  secondaryValue,
  hasLimitedWidth,
}: Props) => {
  const { currentCluster } = useMainContext();
  const [link, setLink] = useState<string>("");
  const [tooltip, setTooltip] = useState<ReactNode>(undefined);
  const { data } = useQuery<GetVersionResponse, Error>({
    queryKey: [queryKey, currentCluster],
    queryFn: queryFn,
    enabled: !disableLink,
  });

  const currentClusterURLParam = currentCluster ? `currentClusterURLParam=${currentCluster}` : "";

  const getWorkloadIdentifiersFromLabel = (splitLabel: string[]) => {
    const isVersionXGreaterOrEqualThanVersionY = getIsVersionXGreaterThanVersionY(
      data?.currentVersion,
      VERSION_THRESHOLD
    );
    let cluster = undefined;
    let namespace = "";
    let workloadName = "";
    let type = "";
    const splitLen = splitLabel.length;
    switch (true) {
      case source === OverviewLinkType.Node:
        type = splitLabel[1] ?? "";
        namespace = splitLabel[0] ?? "";
        workloadName = splitLabel[2] ?? "";
        break;
      case source === OverviewLinkType.Workload:
        type = splitLabel[splitLen - 3] ?? "";
        if (isVersionXGreaterOrEqualThanVersionY) {
          namespace = splitLabel[splitLen - 2] ?? "";
          workloadName = splitLabel[splitLen - 1] ?? "";
        } else {
          namespace = splitLabel[splitLen - 1] ?? "";
          workloadName = splitLabel[splitLen - 2] ?? "";
        }
        break;
      case source === OverviewLinkType.MultiCluster:
        cluster = splitLabel.slice(0, Math.max(0, splitLen - 3)).join("/") ?? "";
        type = splitLabel[splitLen - 3] ?? "";
        namespace = splitLabel[splitLen - 2] ?? "";
        workloadName = splitLabel[splitLen - 1] ?? "";
        break;
    }

    type = getWorkloadType(workloadName, type) ?? type;
    type = workloadTypes?.[type as keyof typeof workloadTypes] ?? type;
    workloadName = getDisplayWorkloadName(workloadName);

    const workloadIdentifiers: WorkloadIdentifiers = {
      cluster: cluster,
      type: type,
      namespace: namespace,
      name: workloadName,
    };

    return workloadIdentifiers;
  };

  const buildWorkloadOverviewLink = (splitLabel: string[]) => {
    const workloadIdentifiers = getWorkloadIdentifiersFromLabel(splitLabel);

    const typeUrlParam = `types=${workloadIdentifiers.type}`;
    const searchTermUrlParam = `searchTerms=${workloadIdentifiers.namespace}/${workloadIdentifiers.name}`;

    if (workloadIdentifiers.cluster !== undefined) {
      const clusterURLParam = `currentClusterURLParam=${workloadIdentifiers.cluster}`;
      return `/?${clusterURLParam}&${searchTermUrlParam}&${typeUrlParam}&openFirstRowWorkloadOverview=1`;
    }

    return `/?${currentClusterURLParam}&${searchTermUrlParam}&${typeUrlParam}&openFirstRowWorkloadOverview=1`;
  };

  const buildTooltip = (splitLabel: string[]) => {
    const workloadIdentifiers = getWorkloadIdentifiersFromLabel(splitLabel);

    return (
      <ExploreEntityTooltip
        namespace={workloadIdentifiers.namespace}
        workloadName={workloadIdentifiers.name}
        type={workloadIdentifiers.type}
        cluster={workloadIdentifiers.cluster}
      />
    );
  };

  useEffect(() => {
    const splitLabel = rawLabel.split("/");

    switch (true) {
      case targetResource === OverviewLinkType.Node: {
        let cluster = splitLabel.slice(0, splitLabel.length - 1).join("/");
        cluster = cluster === "" ? String(currentCluster ?? "") : cluster;
        const nodeId = splitLabel[splitLabel.length - 1] ?? "";
        setLink(buildNodeOverviewLink(nodeId, cluster));
        setTooltip(
          <Typography variant="caption" className="flex flex-col gap-1">
            <span>
              <b>Node:</b> {nodeId}
            </span>
            {cluster && (
              <span>
                <b>Cluster:</b> {cluster}
              </span>
            )}
          </Typography>
        );
        return;
      }
      case targetResource === OverviewLinkType.Workload: {
        //TODO: split this code by row data values
        setLink(buildWorkloadOverviewLink(splitLabel));
        setTooltip(buildTooltip(splitLabel));
        return;
      }
    }
  }, [data, rawLabel]);

  if (disableLink) {
    return (
      <ChartTooltipElement
        color={color}
        value={value}
        secondaryValue={secondaryValue}
        tooltip={tooltip}
        label={label}
        enableCopyTextOnClick={textToCopyOnClick}
        hasLimitedWidth={hasLimitedWidth}
      />
    );
  }

  label = (
    <NavLink to={link} target={openInNewTab ? "_blank" : undefined} className="underline hover:text-main-linkBlue ">
      {label}
    </NavLink>
  );
  return (
    <ChartTooltipElement
      color={color}
      value={value}
      secondaryValue={secondaryValue}
      label={label}
      tooltip={tooltip}
      enableCopyTextOnClick={textToCopyOnClick}
      hasLimitedWidth={hasLimitedWidth}
    />
  );
};

export default LinkableTooltipElement;
