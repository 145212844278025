import { GetTopKQueryKey } from "../../../api/fetcher";
import TopKMultiLineChart from "../../Analytics/AnalyticsV2/Graphs/TopKMultiLineChart";
import { AggregateBy } from "../../Analytics/AnalyticsV2/SelectAggregationView";
import useGetGraphState from "../useGetGraphsState";
import { GENERAL_SYNC_ID } from "../utils";
import { CommonGraphProps } from "./utils";

const WastedCPUGraph = ({ isMulticluster, tooltipTrigger, disabledZoom, updateActiveTooltips }: CommonGraphProps) => {
  const { date, setDate, numberOfElements, aggregationView, frozenAndClickableTab } = useGetGraphState();

  return (
    <TopKMultiLineChart
      title={"Wasted CPU by " + aggregationView.toLowerCase()}
      queryParams={{
        from: date.range ? undefined : date.from,
        to: date.range ? undefined : date.to,
        range: date.range,
        queryKey:
          aggregationView === AggregateBy.Workloads ? GetTopKQueryKey.WastedCpu : GetTopKQueryKey.WastedCpuByNamespace,
      }}
      setDate={setDate}
      isMulticluster={isMulticluster}
      topK={numberOfElements}
      YAxisFormatter={(value) => `${value}mil`}
      tooltipTrigger={tooltipTrigger}
      disabledZoom={disabledZoom}
      updateActiveTooltips={updateActiveTooltips}
      enableCopyTextOnClick
      frozenTooltipType={frozenAndClickableTab}
      syncId={GENERAL_SYNC_ID}
    />
  );
};

export default WastedCPUGraph;
